<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="closeOnBackdrop">
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <CButtonClose
          aria-label="Close"
          class="close"
          @click="modalCallBack(false)" />
      </template>
      <div class="d-flex justify-content-center">
        <div class="qr-code-container">
          <img :src="qrCode" alt="QR code" />
        </div>
      </div>
      <h4 class="text-center">{{ qrName }}</h4>
      <template #footer>
        <div>
          <span v-for="(button, index) in buttons" :key="`button_${index}`">
            <CButton
              class="ml-2 bg-primary text-white"
              @click="downloadQRCode()"
              >{{ button.button || button }}</CButton
            >
          </span>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    isShowPopup: {
      type: Boolean,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalColor: {
      type: String,
      required: true,
    },
    modalCallBack: {
      type: Function,
      required: true,
    },
    qrUrl: {
      type: String,
      required: true,
    },
    qrName: {
      type: String,
      required: true,
    },
    closeOnBackdrop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      qrCode: "",
    };
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    downloadQRCode() {
      const link = document.createElement("a");
      link.download = `${this.qrName}.png`;
      link.href = this.qrCode;
      link.click();
      this.modalCallBack(false);
    },
    generateQRCode() {
      QRCode.toDataURL(this.qrUrl, {
        width: 300,
        errorCorrectionLevel: "H",
      }).then((url) => {
        this.qrCode = url;
      });
    },
  },
};
</script>
